import React, { useState } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';

const JournalHeatMap = ({ journalData }) => {
  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);

  const today = new Date();
  const startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const day = date.getDate();
    return `${monthNames[date.getMonth()]} ${day}${getOrdinalSuffix(day)}`;
  };

  const handleMouseEnter = (event, value) => {
    if (!value || !value.date) {
      setTooltipContent('No journals');
    } else {
      const formattedDate = formatDate(value.date);
      setTooltipContent(`${formattedDate}: ${value.count} journal${value.count !== 1 ? 's' : ''}`);
    }
    setTooltipPosition({ x: event.clientX, y: event.clientY });
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="journal-heatmap">
      <CalendarHeatmap
        startDate={startDate}
        endDate={today}
        values={journalData}
        classForValue={(value) => {
          if (!value) {
            return 'color-empty';
          }
          if (value.count === 1) return 'color-scale-1';
          if (value.count === 2) return 'color-scale-2';
          if (value.count === 3) return 'color-scale-3';
          if (value.count >= 4) return 'color-scale-4';
        }}
        onMouseOver={(event, value) => handleMouseEnter(event, value)}
        onMouseLeave={handleMouseLeave}
      />
      {showTooltip && (
        <div
          className="heatmap-tooltip"
          style={{
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
          }}
        >
          {tooltipContent}
        </div>
      )}
    </div>
  );
};

export default JournalHeatMap;