import React, { useMemo, useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Register the necessary chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const JournalHourlyLineChart = ({ hourlyData }) => {
  // Create a ref for the chart instance
  const chartRef = useRef(null);

  // Memoize the chart data to prevent unnecessary recalculations
  const chartData = useMemo(() => {
    if (!hourlyData || hourlyData.length === 0) {
      return null;
    }

    const localHourlyData = hourlyData
      .map(({ hour, count }) => ({
        hour,
        formattedHour: new Date(0, 0, 0, hour).toLocaleTimeString([], { hour: 'numeric', hour12: true }),
        count,
      }))
      .sort((a, b) => a.hour - b.hour);

    // Normalize the count data
    const maxCount = Math.max(...localHourlyData.map(data => data.count));
    const normalizedData = localHourlyData.map(data => ({
      ...data,
      normalizedCount: data.count / maxCount,
    }));

    // Prepare the data for the chart
    return {
      labels: normalizedData.map(data => data.formattedHour),
      datasets: [
        {
          label: 'Normalized Journal Entries',
          data: normalizedData.map(data => data.normalizedCount),
          originalData: normalizedData, // Store the original data for tooltip access
          borderColor: function(context) {
            const chart = context.chart;
            const {ctx, chartArea} = chart;
            if (!chartArea) {
              return null;
            }
            return getGradient(ctx, chartArea);
          },
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          tension: 0.4,
          fill: true,
        },
      ],
    };
  }, [hourlyData]);

  // Function to create a gradient for the line color
  const getGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
    gradient.addColorStop(0, '#8A2BE280');   // Night: Blue Violet
    gradient.addColorStop(0.25, '#FFA50080'); // Morning: Orange
    gradient.addColorStop(0.5, '#FFFF0080');  // Noon: Yellow
    gradient.addColorStop(0.75, '#00BFFF80'); // Evening: Deep Sky Blue
    gradient.addColorStop(1, '#8A2BE280');   // Night: Blue Violet
    return gradient;
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: false,
          text: 'Time of Day',
          color: '#c9d1d9',
        },
        ticks: {
          color: '#c9d1d9',
        },
        grid: {
          display: false, // This removes the x-axis grid
        },
      },
      y: {
        display: false, // This hides the entire y-axis
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataIndex = context.dataIndex;
            const dataset = context.dataset;
            const originalData = dataset.originalData[dataIndex];
            return `Entries: ${originalData.count} (Normalized: ${context.parsed.y.toFixed(2)})`;
          },
        },
      },
    },
  };

  // Effect to update the chart when data changes
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.update();
    }
  }, [chartData]);

  // Render a message if no data is available
  if (!chartData) {
    return <div className="no-data-message">No data available for chart</div>;
  }

  // Render the chart
  return (
    <div className="journal-hourly-chart">
      <Line ref={chartRef} data={chartData} options={options} />
    </div>
  );
};

export default JournalHourlyLineChart;