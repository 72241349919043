import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./JournalPromptList.css";
import trash from './trashIcon.svg';
import checkmark from './checkmark.svg';
const EmpathAnalytics = require("../../../../EmpathAnalytics");

const JournalPromptsList = ({ 
    user 
}) => {

const [addingPrompt, setAddingPrompt] = useState(false);
const [isPromptLoading, setIsPromptLoading] = useState(false);
const [newPrompt, setNewPrompt] = useState('');
const [items, setItems] = useState([]);

useEffect(() => {
    const fetchPrompts = async () => {
      await refreshJournalPrompts();
    };
    fetchPrompts();
  }, [user]);

const handleAdd = async () => {
    EmpathAnalytics.logEventFirebase('button_click', 'handle_add_prompt');
    setIsPromptLoading(true);
    try {
        const response = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/clients/addJournalPrompt`,
        {
            prompt: newPrompt,
            clientId: user.client_id,
        },
        { withCredentials: true }
        );
        console.log(response);
        setNewPrompt('');
        await refreshJournalPrompts();
    } catch (error) {
        EmpathAnalytics.logEvents('error_message', 'add_journal_prompt_error');
        console.log(error);
        setIsPromptLoading(false);
    }
}

const handleDelete = async (id) =>  {
    EmpathAnalytics.logEventFirebase('button_click', 'delete_journal_prompt');
    setIsPromptLoading(true);
    try {
        const response = await axios.delete(
        `${process.env.REACT_APP_BACK_END_URL}/api/clients/journalPrompt/${id}`,
        { withCredentials: true }
        );
        console.log(response);
        await refreshJournalPrompts();
    } catch (error) {
        EmpathAnalytics.logEvents('error_message', 'handle_delete_journal_prompt_error');
        console.log(error);
        setIsPromptLoading(false);
    }
}

const refreshJournalPrompts = async () => {
    setIsPromptLoading(true);
    console.log(user.client_id);
    try {
        const response = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/clients/fetchJournalPrompts`,
        {
            clientId: user.client_id,
        },
        { withCredentials: true }
        );
        console.log(response);
        setIsPromptLoading(false);
        setItems(response.data)
    } catch (error) {
        EmpathAnalytics.logEvents('error_message', 'refresh_journal_prompts_error');
        console.log(error);
        setIsPromptLoading(false);
    }
}

const handleInputChange = (event) => {
    setNewPrompt(event.target.value); // add this function
  };

return (
    <div className="client-prompts">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {items.map(item => (
          <div key={item.id} style={{ display: 'flex', alignItems: 'center', padding: '0px 10px 10px 0px' }}>
            <div className='journal-prompt-row'>
                <span>{item.prompt}</span>
            </div>
            <button onClick={() => handleDelete(item.id)} className="journal-prompt-row-delete-button">
            <img src={trash} alt="delete" />
          </button>
        </div>
        ))}
            {addingPrompt && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div className='journal-prompt-row'>
              <input 
                type="text" 
                placeholder="Type your prompt and click confirm on the right" 
                className="journal-prompt-row-input" 
                value={newPrompt} 
                onChange={handleInputChange} 
              />
            </div>
            <button onClick={() => handleAdd()} className="journal-prompt-row-confirm-button">
                <img src={checkmark} alt="confirm" />
            </button>
            </div>
        )}
        <button onClick={() => EmpathAnalytics.logEventFirebase('button_click', 'add_prompt') & setAddingPrompt(!addingPrompt)} className="journal-prompt-row-add-button">
        {isPromptLoading ? (
                <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "200px" }}
                >
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                </div>
            ) : (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    &#43; Add Prompt
                </div>
            )}
        </button>
      </div>
    </div>
  );
};

export default JournalPromptsList;