import React from 'react';
import Auth from './Components/Auth/Auth';
import AdminDash from './Components/Dashboard/Dashboard';
import {Router, Routes, Route, useLocation } from 'react-router-dom';
import Register from './Components/Register/Register';
import PerformPasswordReset from './Components/performPasswordReset/PerformPasswordReset';
import SignUpClient from './Components/SignUpClient/SignUpClient';
import Success from './Components/SignUpClient/Success';
import LoginExistingClient from './Components/SignUpClient/LoginExistingClient';
import LandingPage from './Components/LandingWebpage/LandingPage';
import posthog from 'posthog-js';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_APP_ID}`,
  measurementId: `${process.env.REACT_APP_MEASUREMENT_ID}`
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const EmpathAnalytics = require("./EmpathAnalytics");

function App() {
  let location = useLocation();

  React.useEffect(() => {
    posthog.capture('$pageview');
    logEvent(analytics, 'page_view');

    if (location.pathname === '/') {
      EmpathAnalytics.logUTMParameters();
    }
  }, [location]);

  return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Auth />} />
        <Route path="/Dashboard" element={<AdminDash />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/reset-password/:userId/:token" element={<PerformPasswordReset />} />
        <Route path="/sign-up-client/:signUpCode/:token" element={<SignUpClient />} />
        <Route path="/success" element={<Success/>} />
        <Route path="/login-existing-client/:signUpCode/:token" element={<LoginExistingClient />} />
      </Routes>
  );
}

export default App;