import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { addFeedback } from './feedbackService';
import "../Dashboard.css";
const EmpathAnalytics = require("../../../EmpathAnalytics");

function FeedbackSection() {
  const [isFeedbackShown, setIsFeedbackShown] = useState(false);
  const [showFeedbackBtnText, setShowFeedbackBtnText] = useState("Add Feedback");
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackRating, setFeedbackRating] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showFeedbackError, setFeedbackError] = useState(false);

  const handleFeedbackToggle = () => {
    EmpathAnalytics.logEventFirebase('button_click', 'feedback_button_toggle');
    setIsFeedbackShown((prev) => !prev);
    setShowFeedbackBtnText((prev) => 
      prev === "Add Feedback" ? "Close Feedback" : "Add Feedback"
    );
  };

  const handleFeedbackTextChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const handleFeedbackRatingChange = (value) => {
    setFeedbackRating(value);
  };

  const handleFeedbackSubmit = async () => {
    EmpathAnalytics.logEventFirebase('button_click', 'submit_feedback_button');
    if (feedbackText === "" || feedbackRating === 0) {
      setShowConfirmation(false);
      setFeedbackError(true);
    } else {
      try {
        const response = await addFeedback({ feedbackText, feedbackRating });
        if (response.status >= 200 && response.status < 300) {
          setFeedbackError(false);
          setFeedbackText("");
          setFeedbackRating(0);
          setShowConfirmation(true);
        } else {
          console.error("Failed to save Feedback:", response);
        }
      } catch (error) {
        EmpathAnalytics.logEvents('error_message', 'add_feedback_error');
        console.error("Error saving feedback:", error);
      }
    }
  };

  return (
    <div className="feedbackSection">
      <div className="feedbackButtonContainer">
      <button onClick={handleFeedbackToggle} className="show-feedback-button">
        {showFeedbackBtnText}
      </button>
      </div>
      {isFeedbackShown && (
        <div className="feedbackForm">
          <div className="feedbackTitle">How Helpful Was The Summary?</div>
          <textarea
            placeholder="Was this a helpful summary? Please provide feedback here."
            value={feedbackText}
            onChange={handleFeedbackTextChange}
            className="feedbackTextbox"
          />
          <div className="starRating">
            {[1, 2, 3, 4, 5].map((star) => (
              <FontAwesomeIcon
                key={star}
                icon={star <= feedbackRating ? faStar : faStarRegular}
                className="star"
                onClick={() => handleFeedbackRatingChange(star)}
                style={{ fontSize: "1.8rem", color: "#ffd700" }}
              />
            ))}
          </div>
          <button onClick={handleFeedbackSubmit} className="gradientButton">
            Submit Feedback
          </button>
          {showConfirmation && (
            <div className="confirmationMessage">
              Thank you! Your feedback has been submitted. Look out for updates and improvements based on this.
            </div>
          )}
          {showFeedbackError && (
            <div className="feedbackMessage">
              Please fill out all fields before submitting.
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FeedbackSection;