import { format, subDays } from 'date-fns';

const generateDate = (index) => {
  const currentDate = new Date();
  return format(subDays(currentDate, index), 'yyyy-MM-dd');
};

export const exampleClients = [
  {
    id: 'exampleClient',
    name: "John Doe",
    age: 30,
    email: "example@example.com",
    profile_picture: "https://example.com/profile_picture.jpg",
    notes: "Example notes",
    last_visited: generateDate(0),
  },
];

export const exampleJournals = [
  {
    journal_id: 1,
    client_id: 1,
    title: "College",
    text: "It's been a wild ride since I last wrote in this journal. I'm now 21, and I can barely recognize the person I was just a year ago. I'm in my third year of college, and it feels like just yesterday I was moving into my dorm, nervous and unsure of what the future held.\n" +
    '\n' +
    "I'm studying computer science, and I'm loving every minute of it. I never thought I would be the type of person to enjoy programming, but there's something about the challenge of solving problems and creating something from scratch that really speaks to me. I've even started working on a few projects of my own, trying to build apps and websites in my spare time. It's been a steep learning curve, but it's been worth it.\n" +
    '\n' +
    "I've also been trying to get more involved on campus. I joined a few clubs, and I even ran for a position in student government. It was a little intimidating at first, but I'm so glad I did it. I've met some amazing people, and I feel like I'm really making a difference.\n" +
    '\n' +
    "But it's not all been smooth sailing. I've had my fair share of struggles, too. I went through a breakup a few months ago, and it was tough. I thought I was doing okay, but it hit me hard when I saw my ex at a party a few weeks ago. I tried to play it cool, but I could feel my heart racing and my palms sweating. It was like I was back in high school all over again.\n" +   
    '\n' +
    "I've also been dealing with some family drama. My parents are getting divorced, and it's been really hard to watch. They've been fighting a lot, and it's hard to know what to do. I feel like I'm stuck in the middle, trying to navigate this whole thing on my own.\n" +
    '\n' +
    "Despite all of that, I'm trying to stay positive. I know that life can be tough sometimes, but I'm determined to make the most of my time in college. I'm trying to soak up every experience, meet new people, and take advantage of every opportunity that comes my way.\n" +
    '\n' +
    "I'm excited to see what the rest of my college career has in store for me. I know there will be ups and downs, but I'm ready for whatever comes my way.\n" +
    '\n' +
    "As I close this journal entry, I'm feeling grateful for the journey that has brought me to this point. I'm grateful for the people in my life, even the ones who have hurt me. I'm grateful for the experiences that have shaped me, even the ones that have been difficult. And I'm excited for what's to come. Bring on the future!",
    feeling: 0.08,
    intensity: 5,
    calculatedIntensity: 0.11399999999999999,
    calculatedFeeling: 0.9965,
    approach_withdrawal: 0.6900000000000001,
    mood_trigger: "Coffee",
    platform: "iOS",
    voice_journal_path: "https://com.com/",
    created_at: generateDate(0),
    updated_at: generateDate(0),
    entry_date: generateDate(0),
    isShared: 1,
  },
  {
    journal_id: 2,
    client_id: 1,
    title: "Reflecting on Junior Year",
    text: "It's hard to believe that my first semester of junior year is already over. It feels like just yesterday I was moving into my dorm and getting ready for another year of college.\n" +
    '\n' +
    'Looking back on the semester, I have to say that it was a bit of a rollercoaster. I took on a lot of responsibilities, between classes, work, and extracurricular activities. There were times when I felt completely overwhelmed and stressed out, but I pushed through it and made it work.\n' +
    '\n' +
    "One of the highlights of the semester was definitely my programming project. I worked on creating a mobile app that helps people find local volunteer opportunities. It was a lot of work, but it was also really rewarding to see my idea come to life. I learned so much about programming and app development, and I'm excited to continue working on it in the spring semester.\n" +
    '\n' +
    "Another highlight was my 21st birthday. I celebrated with my friends by going out for dinner and drinks. It was a lot of fun, but it was also a bit surreal. I can't believe I'm already 21. It feels like just yesterday I was graduating from high school.\n" +
    '\n' +
    "However, the semester wasn't all smooth sailing. I had a bit of a rough patch in my personal life. I went through a breakup, and it was tough. I thought I was doing okay, but it hit me hard when I saw my ex at a party a few weeks ago. I tried to play it cool, but I could feel my heart racing and my palms sweating. It was like I was back in high school all over again.\n" + 
    '\n' +
    "Despite that, I'm trying to stay positive. I know that life can be tough sometimes, but I'm determined to make the most of my time in college. I'm excited to see what the spring semester has in store for me.",
    feeling: 0.58,
    intensity: -0.33,
    approach_withdrawal: 0.07,
    calculatedIntensity: 0.186,
    calculatedFeeling: 0.9972,
    mood_trigger: "",
    platform: "iOS",
    voice_journal_path: "https://com.com/",
    created_at: generateDate(0),
    updated_at: generateDate(0),
    entry_date: generateDate(0),
    isShared: 1,
  },
  {
    journal_id: 3,
    client_id: 1,
    title: "Rainy Days and Reflection",
    text: "It's a cold, rainy day outside, and I'm feeling a bit down. I'm not sure if it's the weather or just life in general, but I can't seem to shake this funk I'm in.\n" +
    '\n' +
    "I'm sitting in my dorm room, staring out the window at the rain. It's a gloomy day, and it's matching my mood perfectly. I'm feeling a bit lost and uncertain about my future. I'm in my third year of college, and I feel like I should have my life together by now. But the truth is, I have no idea what I want to do with my life.\n" +
    '\n' +
    "I've been trying to decide on a major, but nothing seems to fit. I'm interested in so many things, but I can't seem to find my passion. I feel like I'm floating through college, taking classes but not really knowing where I'm headed.\n" +
    '\n' +
    "I'm also feeling a bit lonely. I've made some great friends in college, but sometimes it feels like we're all just going through the motions. We're all so busy with our own lives that we don't have time to really connect.\n" +
    '\n' +
    "I'm trying to stay positive, but it's hard on days like today. The rain is making me feel melancholy, and I can't help but feel like I'm stuck in a rut.\n" +
    '\n' +
    "I know I'm not alone in feeling this way. I know that lots of people struggle with figuring out their lives, especially in college. But it's hard to remember that when you're in the midst of it all.\n" +
    '\n' +
    "I'm trying to focus on the present moment, rather than worrying about the future. I'm taking things one day at a time, and trying to find joy in the little things. Like the sound of the rain on my window, or the warmth of my cup of tea.",
    feeling: -0.55,
    intensity: -0.42,
    calculatedIntensity: 0.081,
    calculatedFeeling: 0.9885,
    approach_withdrawal: -1,
    mood_trigger: "Good news",
    platform: "iOS",
    voice_journal_path: "https://com.com/",
    created_at: generateDate(0),
    updated_at: generateDate(0),
    entry_date: generateDate(0),
    isShared: 1,
  },
  {
    journal_id: 4,
    client_id: 1,
    title: "Hectic Days and Coffee Break",
    text: "It's been a crazy day so far. I woke up early this morning to get to my 8 am class on time. I had a paper due in one of my classes, so I spent most of the morning working on that. After class, I had a meeting with my academic advisor to discuss my plans for next semester. Now, I'm sitting in the student union, sipping on a coffee and trying to gather my thoughts.\n" + 
    '\n' +
    "I have to say, this semester has been pretty hectic. I'm taking a lot of credits, and it feels like I'm constantly working on something. I'm also trying to find an internship for the summer, which is proving to be more challenging than I thought it would be.\n" +
    '\n' +
    "Despite all the craziness, I'm trying to stay on top of things. I'm using every spare moment to work on my assignments, and I'm trying to stay organized. It's not always easy, but I know it will be worth it in the end. I'm also trying to make time for my friends and family. It's hard when everyone has such busy schedules, but we're making it work. We had a movie night last night, and it was so much fun. It's nice to have people around me who understand what I'm going through. I'm feeling a bit overwhelmed at the moment. There's just so much to do, and I'm not sure how I'm going to get it all done. But, I'm determined to make it work. I know that if I push through this, I'll come out even stronger on the other side. ",
    feeling: 0.06,
    intensity: 0.39,
    calculatedIntensity: 0.093,
    calculatedFeeling: 0.9756,
    approach_withdrawal: 0.89,
    mood_trigger: "Coffee",
    platform: "iOS",
    voice_journal_path: "https://com.com/",
    created_at: generateDate(0),
    updated_at: generateDate(0),
    entry_date: generateDate(0),
    isShared: 1,
  },
  {
    journal_id: 5,
    client_id: 1,
    title: "Slow Sundays and Reflections",
    text: "It's a slow Sunday afternoon, and I'm feeling a bit meh. I don't have any classes today, and I'm trying to make the most of my free time. I slept in late, had a big breakfast, and spent some time reading a book.\n" +   
    '\n' +
    "I'm feeling a bit reflective today. I've been thinking about my life a lot lately, and I'm trying to make sense of it all. I'm at a point where I'm trying to figure out what I want to do with my life. It's a big question, and it's one that I'm not sure I have an answer to yet.\n" +
    '\n' +
    "I'm feeling a bit lost, to be honest. I know I'm not alone in feeling this way, but it's hard to shake off the feeling that I'm adrift. I'm trying to focus on the present moment, rather than worrying about the future. It's not always easy, but I'm trying to take things one day at a time. I'm also thinking about my relationships. I've made some great friends in college, but sometimes I feel like we're all just going through the motions. We're all so busy with our own lives that we don't have time to really connect. I'm trying to stay positive, though. I know that life can be tough sometimes, but I'm determined to make the most of my time in college. I'm trying to soak up every experience, and to learn as much as I can.",
    feeling: -0.09,
    intensity: -0.58,
    calculatedIntensity: 0.07100000000000001,
    calculatedFeeling: 0.9744,
    approach_withdrawal: -1,
    mood_trigger: "Coffee",
    platform: "iOS",
    voice_journal_path: "https://com.com/",
    created_at: generateDate(0),
    updated_at: generateDate(0),
    entry_date: generateDate(0),
    isShared: 1,
  },
];

export const exampleAppHealthData = [
    // Sample Sleep Data (Including detailed sleep types)
    ...Array.from({ length: 28 }, (_, i) => {
      const startDate = generateDate(i);
      const endDate = startDate;
      return {
        id: i + 1,
        client_id: 1,
        type: "sleep", // General sleep category
        value: Math.floor(Math.random() * (27000 - 20000 + 1)) + 20000, // Sleep in seconds
        startDate,
        endDate,
        created_at: generateDate(0),
        updated_at: generateDate(0),
      };
    }),
  
    // Sleep detailed categories
    ...Array.from({ length: 28 }, (_, i) => {
      const startDate = generateDate(i);
      const endDate = startDate;
      return {
        id: i + 101,
        client_id: 1,
        type: "inBed", // In bed but not necessarily asleep
        value: Math.floor(Math.random() * (36000 - 20000 + 1)) + 20000, // In bed time in seconds
        startDate,
        endDate,
        created_at: generateDate(0),
        updated_at: generateDate(0),
      };
    }),
    ...Array.from({ length: 28 }, (_, i) => {
      const startDate = generateDate(i);
      const endDate = startDate;
      return {
        id: i + 201,
        client_id: 1,
        type: "asleepCore", // Core sleep type
        value: Math.floor(Math.random() * (18000 - 10000 + 1)) + 10000, // Core sleep in seconds
        startDate,
        endDate,
        created_at: generateDate(0),
        updated_at: generateDate(0),
      };
    }),
    ...Array.from({ length: 28 }, (_, i) => {
      const startDate = generateDate(i);
      const endDate = startDate;
      return {
        id: i + 301,
        client_id: 1,
        type: "asleepDeep", // Deep sleep category
        value: Math.floor(Math.random() * (9000 - 3000 + 1)) + 3000, // Deep sleep in seconds
        startDate,
        endDate,
        created_at: generateDate(0),
        updated_at: generateDate(0),
      };
    }),
    ...Array.from({ length: 28 }, (_, i) => {
      const startDate = generateDate(i);
      const endDate = startDate;
      return {
        id: i + 401,
        client_id: 1,
        type: "awake", // Time awake during sleep cycle
        value: Math.floor(Math.random() * (6000 - 1000 + 1)) + 1000, // Awake time in seconds
        startDate,
        endDate,
        created_at: generateDate(0),
        updated_at: generateDate(0),
      };
    }),
  
    // Distance Data
    ...Array.from({ length: 28 }, (_, i) => {
      const startDate = generateDate(i);
      const endDate = startDate;
      return {
        id: i + 11,
        client_id: 1,
        type: "distance", 
        value: Math.floor(Math.random() * (5000 - 1000 + 1)) + 1000, // Distance in meters
        startDate,
        endDate,
        created_at: generateDate(0),
        updated_at: generateDate(0),
      };
    }),
  
    // Steps Data
    ...Array.from({ length: 28 }, (_, i) => {
      const startDate = generateDate(i);
      const endDate = startDate;
      return {
        id: i + 21,
        client_id: 1,
        type: "steps", 
        value: Math.floor(Math.random() * (10000 - 2000 + 1)) + 2000, // Steps count
        startDate,
        endDate,
        created_at: generateDate(0),
        updated_at: generateDate(0),
      };
    }),
  
    // Active Energy Burned Data
    ...Array.from({ length: 28 }, (_, i) => {
      const startDate = generateDate(i);
      const endDate = startDate;
      return {
        id: i + 501,
        client_id: 1,
        type: "activeEnergyBurned", // Active calories burned
        value: Math.floor(Math.random() * (500 - 100 + 1)) + 100, // Energy burned in kcal
        startDate,
        endDate,
        created_at: generateDate(0),
        updated_at: generateDate(0),
      };
    }),
  
    // Dietary Energy Consumed Data
    ...Array.from({ length: 28 }, (_, i) => {
      const startDate = generateDate(i);
      const endDate = startDate;
      return {
        id: i + 601,
        client_id: 1,
        type: "dietaryEnergyConsumed", // Dietary calories consumed
        value: Math.floor(Math.random() * (2500 - 1500 + 1)) + 1500, // Energy consumed in kcal
        startDate,
        endDate,
        created_at: generateDate(0),
        updated_at: generateDate(0),
      };
    }),
  
    // Exercise Minutes Data
    ...Array.from({ length: 28 }, (_, i) => {
      const startDate = generateDate(i);
      const endDate = startDate;
      return {
        id: i + 701,
        client_id: 1,
        type: "exerciseMinutes", // Exercise duration
        value: Math.floor(Math.random() * (90 - 30 + 1)) + 30, // Exercise minutes
        startDate,
        endDate,
        created_at: generateDate(0),
        updated_at: generateDate(0),
      };
    }),
  ];
  

export const exampleSummary = {
  summary: "Here is a summary of the key themes and insights from your client's recent journal entries, tailored to align with the principles and objectives of the Psychodynamic approach:\n\n\\n\n- Grappling with significant life transitions and changes, both exciting and challenging, as the client navigates the college experience [1, 2]\n- Exploring and developing new interests and passions, such as computer science and programming, which provide a sense of purpose and fulfillment [1, 2]\n\\n\n- Struggling with the emotional impact of personal relationships, particularly a recent breakup, and the feelings of vulnerability and self-consciousness this has evoked [1, 2]\n\\n\n- Experiencing a range of emotions, from enthusiasm and optimism to feelings of being overwhelmed, lost, and disconnected, reflecting the client's complex inner world [1, 2, 3, 4, 5]\n\\n\n- Grappling with uncertainty about the future and the search for meaning and direction, highlighting the client's need for self-exploration and identity formation [3, 5]\n\\n\n- Navigating family dynamics and the emotional impact of parental conflict, which may be influencing the client's sense of stability and security [1]\n\\n\n- Expressing a desire to stay positive and make the most of the college experience, despite the challenges, suggesting an underlying resilience and a willingness to engage in personal growth [1, 2, 3, 4, 5]\n\nThese themes and insights reflect the client's internal struggles, relationships, and search for meaning, aligning with the Psychodynamic approach's focus on understanding the individual's emotional experiences, unconscious processes, and the influence of past experiences on present-day functioning. By exploring these areas, I can help the client gain greater self-awareness, process unresolved conflicts, and facilitate personal growth and transformation."
}

export const exampleEmotionData = [
  {
      category: "Alert/Anxious",
      feelingValue: 1.05,
      calculatedValue: 0
  },
  {
      category: "Excited/Elated",
      feelingValue: 0,
      calculatedValue: 0
  },
  {
      category: "Pleased",
      feelingValue: 1.05,
      calculatedValue: 1.05
  },
  {
      category: "Content/Relaxed",
      feelingValue: 1.05,
      calculatedValue: 0
  },
  {
      category: "Calm/Indifferent",
      feelingValue: 1.05,
      calculatedValue: 0
  },
  {
      category: "Depressed/Bored",
      feelingValue: 1.05,
      calculatedValue: 0
  },
  {
      category: "Disappointed",
      feelingValue: 0,
      calculatedValue: 0
  },
  {
      category: "Angry/Frustrated",
      feelingValue: 0,
      calculatedValue: 0
  }
]

export const journalExampleData = [
  { date: '2024-10-08', count: 1 },
  { date: '2024-10-08', count: 2 },
  { date: '2024-10-05', count: 1 },
  { date: '2024-10-07', count: 3 },
  { date: '2024-10-08', count: 2 },
];

export const hourlyExampleJournalData = [
  { hour: 0, count: 2 },
  { hour: 2, count: 3 },
  { hour: 4, count: 1 },
  { hour: 6, count: 4 },
  { hour: 8, count: 2 },
  { hour: 10, count: 2 },
  { hour: 12, count: 2 },
  { hour: 14, count: 2 },
  { hour: 16, count: 2 },
  { hour: 18, count: 2 },
  { hour: 20, count: 2 },
  { hour: 22, count: 2 },
];