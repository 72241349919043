import * as React from "react";

function IconCloudyFill(props) {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M13.405 7.027a5.001 5.001 0 00-9.499-1.004A3.5 3.5 0 103.5 13H13a3 3 0 00.405-5.973z" />
    </svg>
  );
}

export default IconCloudyFill;