import posthog from "posthog-js";
import { getAnalytics, logEvent } from "firebase/analytics";

//Logs event in both posthog and firebase
const logEvents = (eventType, eventName) => {
    const analytics = getAnalytics();

    logEvent(analytics, eventType, {name: eventName});
    posthog.capture(eventType, {name: eventName});
};

//Logs event in just firebase
const logEventFirebase = (eventType, eventName) => {
    const analytics = getAnalytics();

    logEvent(analytics, eventType, {name: eventName});
};

//UTM Parameters
const getUTMParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return {
        utm_source: urlParams.get('utm_source'),
        utm_medium: urlParams.get('utm_medium'),
        utm_campaign: urlParams.get('utm_campaign')
    };
};

//If UTM parameters are present, log them to firebase
const logUTMParameters = () => {
    const analytics = getAnalytics();
    const utmParams = getUTMParameters();

    if(utmParams.utm_source) {
        logEvent(analytics, 'utm_tracking', utmParams);
        posthog.capture('utm_tracking', utmParams);
    }
};

export { logEvents,logEventFirebase, logUTMParameters };