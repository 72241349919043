import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
const EmpathAnalytics = require("../../../EmpathAnalytics");

const useTherapistClients = () => {
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const fetchTherapistClients = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_END_URL}/api/clients/fetchTherapistClients`,
        { withCredentials: true }
      );
      setUserList(response.data);
      
      if (response.data.length > 0 && !selectedUser) {
        const storedSelectedClient = localStorage.getItem("selectedClient");
        if (storedSelectedClient) {
          setSelectedUser(JSON.parse(storedSelectedClient));
        } else {
          setSelectedUser(response.data[0]);
        }
      }
      
      setIsLoading(false);
    } catch (error) {
      EmpathAnalytics.logEvents('error_message', 'fetch_clients_error');
      console.log(error);
      setIsLoading(false);
    }
  }, [selectedUser]);

  useEffect(() => {
    fetchTherapistClients();
  }, [fetchTherapistClients]);

  useEffect(() => {
    if (selectedUser) {
      localStorage.setItem("selectedClient", JSON.stringify(selectedUser));
    }
  }, [selectedUser]);

  return { userList, setUserList, selectedUser, setSelectedUser, isLoading, refreshClients: fetchTherapistClients };
};

export default useTherapistClients;