import { useRef, useEffect, useState } from 'react';
import RecordRTC from 'recordrtc';
import axios from 'axios';
const EmpathAnalytics = require("../../EmpathAnalytics");

const TranscribeAudio = ({ onTranscriptionComplete, isTranscribing, setIsTranscribing, setNewNote }) => {
  const socket = useRef(null);
  const recorder = useRef(null);
  const [transcript, setTranscript] = useState('');

  const generateTranscript = async () => {
    setIsTranscribing(true);
    try {
      let token = '';
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACK_END_URL}/api/therapists/getAssemblyAIToken`, 
        { withCredentials: true });
        token = response.data.token;
      } catch (error) {
        EmpathAnalytics.logEvents('error_message', 'get_AssemblyAI_token_error');
        console.log("Error getting AssemblyAI Token", error);
      }
      if (token) {
        socket.current = new WebSocket(`wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${token}`);
    
        const texts = {};
        socket.current.onmessage = (voicePrompt) => {
          let msg = '';
          const res = JSON.parse(voicePrompt.data);
          texts[res.audio_start] = res.text;
          const keys = Object.keys(texts);
          keys.sort((a, b) => a - b);
          for (const key of keys) {
            if (texts[key]) {
              msg += ` ${texts[key]}`;
            }
          }
          setTranscript(msg);
          setNewNote(msg);
        };
    
        socket.current.onerror = (event) => {
          EmpathAnalytics.logEvents('error_message', 'socket_current_error');
          console.error(event);
          socket.current.close();
          setIsTranscribing(false);
        };
    
        socket.current.onclose = (event) => {
          socket.current = null;
          setIsTranscribing(false);
        };
    
        socket.current.onopen = () => {
          navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
              recorder.current = new RecordRTC(stream, {
                type: 'audio',
                mimeType: 'audio/webm;codecs=pcm',
                recorderType: RecordRTC.StereoAudioRecorder,
                timeSlice: 250,
                desiredSampRate: 16000,
                numberOfAudioChannels: 1,
                bufferSize: 4096,
                audioBitsPerSecond: 128000,
                ondataavailable: (blob) => {
                  const reader = new FileReader();
                  reader.onload = () => {
                    const base64data = reader.result;
                    if (socket.current) {
                      socket.current.send(JSON.stringify({ audio_data: base64data.split('base64,')[1] }));
                    }
                  };
                  reader.readAsDataURL(blob);
                },
              });
              recorder.current.startRecording();
            })
            .catch((err) => {
              EmpathAnalytics.logEvents('error_message', 'socket_current_onopen_error');
              console.error(err);
              setIsTranscribing(false);
            });
        };
      }
    } catch (error) {
      EmpathAnalytics.logEvents('error_message', 'generate_transcript_error');
      console.error(error);
      setIsTranscribing(false);
    }
  };

  const endTranscription = () => {
    setIsTranscribing(false);

    if (socket.current) {
      socket.current.send(JSON.stringify({ terminate_session: true }));
      socket.current.close();
      socket.current = null;
    }

    if (recorder.current) {
      recorder.current.pauseRecording();
      recorder.current = null;
    }

    if (onTranscriptionComplete) {
      onTranscriptionComplete(transcript);
    }
  };

  useEffect(() => {
    if (isTranscribing) {
      generateTranscript();
    } else {
      endTranscription();
    }

    return () => {
      if (recorder.current) {
        recorder.current.pauseRecording();
        recorder.current = null;
      }
      if (socket.current) {
        socket.current.close();
        socket.current = null;
      }
    };
  }, [isTranscribing]);

  return null;
};

export default TranscribeAudio;