import { useState, useCallback } from 'react';
import axios from 'axios';
 
const EmpathAnalytics = require("../../../EmpathAnalytics");

const useHideExampleClient = () => {
  const [isExampleClientHidden, setIsExampleClientHidden] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Method to check the hideExampleClient status
  const checkHideExampleClient = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_END_URL}/api/therapists/getHideExampleClient`,
        {
          withCredentials: true
        }
      );
      setIsExampleClientHidden(response.data.hideExampleClient);
    } catch (error) {
      EmpathAnalytics.logEvents('error_message', 'check_hide_example_client_error');
      console.error("Error checking hideExampleClient status:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Method to set hideExampleClient to true (hide the example client)
  const setHideExampleClient = useCallback(async () => {
    try {
      setIsLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/therapists/hideExampleClient`,
        { withCredentials: true }
      );
      setIsExampleClientHidden(true); // Update state to reflect that the client is now hidden
    } catch (error) {
      EmpathAnalytics.logEvents('error_message', 'set_hide_example_client_error');
      console.error("Error setting hideExampleClient:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isExampleClientHidden,
    isLoading,
    checkHideExampleClient,
    setHideExampleClient
  };
};

export default useHideExampleClient;