import React, { useState, useEffect } from "react";
import { exampleClients, exampleJournals } from "./ExampleClientData";
import useHideExampleClient from './Hooks/useHideExampleClient';

const EmpathAnalytics = require("../../EmpathAnalytics");

const UserListSidebar = ({
  isOpen,
  toggleSidebar,
  isLoading,
  userList,
  searchTerm,
  setSearchTerm,
  setUserNotes,
  setUserJournals,
  handleShowInviteEmailModal,
  handleprocessUserLogout,
  parseUserLastVisit,
  setSelectedUser,
  setStartDate,
  selectedUser,
  requestUnlinkClient,
  clientLimit,
  clientCount,
  retrieveClientJournalEntries,
  startDate,
  endDate,
}) => {
  const [localExampleClients, setLocalExampleClients] = useState(exampleClients);
  const sidebarClass = isOpen ? "userList open" : "userList";

  const { 
    isExampleClientHidden, 
    isLoading: isHideLoading, 
    checkHideExampleClient, 
    setHideExampleClient 
  } = useHideExampleClient();

  useEffect(() => {
    const fetchClientsAndCheckHideStatus = async () => {
      try {
        await checkHideExampleClient();
      } catch (error) {
        console.error("Error fetching client or hide status", error);
      }
    };
  
    fetchClientsAndCheckHideStatus();
  }, [checkHideExampleClient]);
  
  useEffect(() => {
    if (isExampleClientHidden) {
      setLocalExampleClients([]);
    } else {
      setLocalExampleClients(exampleClients)
    }
  }, [isExampleClientHidden]);

  const handleClientClick = (user) => {
    setSelectedUser(user);
    setUserNotes(user.notes ?? "No notes yet... click to edit");

    // Check if the clicked user is the example client
    if (user.id === 'exampleClient') {
      setUserJournals(exampleJournals); // Set example journals
    } else {
      retrieveClientJournalEntries(user, startDate, endDate); // For real clients
    }

    const oneMonthAgo = new Date();
    oneMonthAgo.setDate(oneMonthAgo.getDate() - 31);
    setStartDate(oneMonthAgo);
  };

  const handleDeleteExampleClient = (userId) => {
    // Remove the example client from local state
    setLocalExampleClients((prevClients) =>
      prevClients.filter((client) => client.id !== userId)
    );
    console.log(`Deleted example client with ID: ${userId}`);

    setHideExampleClient();
  };

  // Combine the real user list with the example clients
  const displayedUserList = [...localExampleClients, ...userList];

  return (
    <div className={sidebarClass}>
      <div className="userListLogo">
        <div className="invite-client-container">
          <button
            className="invite-client-btn"
            onClick={handleShowInviteEmailModal}
          >
            Invite New Client
          </button>
        </div>
        <div className="seats-used-container">
          <p className="seats-used">{clientCount}/{clientLimit} Seats Used</p>
        </div>
      </div>

      <input
        type="text"
        placeholder="Search for clients.."
        className="searchBar"
        onChange={(e) => setSearchTerm(e.target.value) & EmpathAnalytics.logEvents('search_query')}
        value={searchTerm}
      />

      <div className="subUserList">
        {isLoading || isHideLoading? (
          <div className="loadingContainer">
            <div className="spinner-grow text-light" role="status"></div>
            <div
              className="spinner-grow text-light me-2 ms-2"
              role="status"
            ></div>
            <div className="spinner-grow text-light" role="status"></div>
          </div>
        ) : (
          displayedUserList
            .filter((user) =>
              user.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((user) => (
              <div
                key={user.id}
                className={`user ${
                  selectedUser && selectedUser.id === user.id
                    ? "selectedUser"
                    : ""
                }`}
                onClick={() => handleClientClick(user)}
              >
                <div className="user-name">
                  {user.profile_picture && (
                    <img
                      src={user.profile_picture}
                      alt={`${user.name}'s profile`}
                      className="user-img"
                    />
                  )}
                  <div className="user-name-title">
                    {user.name}
                  </div>
                </div>

                {user.id === 'exampleClient' ? (
                  <button
                    className="Unlink-user-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteExampleClient(user.id); // Handle example client deletion
                    }}
                  >
                    Delete Example Client
                  </button>
                ) : (
                  userList.length !== 0 && (
                    <button
                      className="Unlink-user-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        requestUnlinkClient(user.id);
                      }}
                    >
                      Unlink
                    </button>
                  )
                )}
              </div>
            ))
        )}
      </div>

      <div className="processUserLogoutButtonContainer p-3">
        <button
          className="btn btn-secondary w-100"
          onClick={handleprocessUserLogout}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default UserListSidebar;
