import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import React, { useRef, useEffect } from 'react';
GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs';

const PDFDocument = React.memo(({ file }) => {
    const iframeRef = useRef(null);

    useEffect(() => {
      if (iframeRef.current) {
        iframeRef.current.src = file;
      }
    }, [file]);

    return (
      <div>
        {file ? (
          <iframe
            ref={iframeRef}
            width="100%"
            height="700px"
            style={{ border: 'none' }}
            title="PDF Viewer"
          >
            This browser does not support PDFs. Please download the PDF to view it: <a href={file}>Download PDF</a>
          </iframe>
        ) : (
          <p>No PDF available.</p>
        )}
      </div>
    );
  }, (prevProps, nextProps) => prevProps.file === nextProps.file);

export default PDFDocument;