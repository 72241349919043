import React from 'react';
import SummaryButtons from '../Buttons/ButtonsHeader';
import SummarySection from '../Summary/SummarySection';
const EmpathAnalytics = require("../../../EmpathAnalytics");

const ClientSummary = ({
  userSummary,
  isGeneratingSummary,
  journalIdMap,
  onGenerateSummary,
  onDateChange,
  userJournals,
  onScrollToHealth,
  showSummary,
  setExpandedJournalIndex
}) => {
    const handleOneDaySummary = () => {
        EmpathAnalytics.logEventFirebase('button_click', 'oneDaySummary');
        onDateChange(1);
        onGenerateSummary();
      };
    
      const handleOneWeekSummary = () => {
        EmpathAnalytics.logEventFirebase('button_click', 'oneWeekSummary');
        onDateChange(7);
        onGenerateSummary();
      };
    
      const handleTwoWeekSummary = () => {
        EmpathAnalytics.logEventFirebase('button_click', 'twoWeekSummary');
        onDateChange(14);
        onGenerateSummary();
      };
    
      const handleOneMonthSummary = () => {
        EmpathAnalytics.logEventFirebase('button_click', 'oneMonthSummary');
        onDateChange(31);
        onGenerateSummary();
      };
    
  return (
    <div className="clientSummary">
      <SummaryButtons
        userJournals={userJournals}
        onOneDaySummary={handleOneDaySummary}
        onOneWeekSummary={handleOneWeekSummary}
        onTwoWeekSummary={handleTwoWeekSummary}
        onOneMonthSummary={handleOneMonthSummary}
        onGenerateSummary={onGenerateSummary}
        onShowHealthData={onScrollToHealth}
        isGeneratingSummary={isGeneratingSummary}
      />
      {showSummary && (
      <SummarySection
        userSummary={userSummary}
        isGeneratingSummary={isGeneratingSummary}
        journalIdMap={journalIdMap}
        userJournals={userJournals}
        setExpandedJournalIndex={setExpandedJournalIndex}
        />
      )}
    </div>
  );
};

export default ClientSummary;