import * as React from "react";

function IconCloudSnowFill(props) {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M2.625 11.5a.25.25 0 01.25.25v.57l.501-.287a.25.25 0 01.248.434l-.495.283.495.283a.25.25 0 01-.248.434l-.501-.286v.569a.25.25 0 11-.5 0v-.57l-.501.287a.25.25 0 01-.248-.434l.495-.283-.495-.283a.25.25 0 01.248-.434l.501.286v-.569a.25.25 0 01.25-.25zm2.75 2a.25.25 0 01.25.25v.57l.5-.287a.25.25 0 01.249.434l-.495.283.495.283a.25.25 0 01-.248.434l-.501-.286v.569a.25.25 0 11-.5 0v-.57l-.501.287a.25.25 0 01-.248-.434l.495-.283-.495-.283a.25.25 0 01.248-.434l.501.286v-.569a.25.25 0 01.25-.25zm5.5 0a.25.25 0 01.25.25v.57l.5-.287a.25.25 0 01.249.434l-.495.283.495.283a.25.25 0 01-.248.434l-.501-.286v.569a.25.25 0 01-.5 0v-.57l-.501.287a.25.25 0 01-.248-.434l.495-.283-.495-.283a.25.25 0 01.248-.434l.501.286v-.569a.25.25 0 01.25-.25zm-2.75-2a.25.25 0 01.25.25v.57l.5-.287a.25.25 0 01.249.434l-.495.283.495.283a.25.25 0 01-.248.434l-.501-.286v.569a.25.25 0 11-.5 0v-.57l-.501.287a.25.25 0 01-.248-.434l.495-.283-.495-.283a.25.25 0 01.248-.434l.501.286v-.569a.25.25 0 01.25-.25zm5.5 0a.25.25 0 01.25.25v.57l.5-.287a.25.25 0 01.249.434l-.495.283.495.283a.25.25 0 01-.248.434l-.501-.286v.569a.25.25 0 01-.5 0v-.57l-.501.287a.25.25 0 11-.248-.434l.495-.283-.495-.283a.25.25 0 01.248-.434l.501.286v-.569a.25.25 0 01.25-.25zm-.22-7.223a5.001 5.001 0 00-9.499-1.004A3.5 3.5 0 103.5 10.25H13a3 3 0 00.405-5.973z" />
    </svg>
  );
}

export default IconCloudSnowFill;