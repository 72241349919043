// summaryUtils.js
export const renderSummaryWithReferences = (summaryText, journalIdMap, userJournals, setExpandedJournalIndex) => {
  if (typeof summaryText !== "string") {
    summaryText = summaryText.toString();
  }
  
  const scrollToJournal = (sequentialJournalId) => {
    // Convert sequentialJournalId to number since keys in journalIdMap might be stored as strings
    sequentialJournalId = Number(sequentialJournalId);

    // Use the journalIdMap to find the original journal ID
    const originalJournalId = journalIdMap[sequentialJournalId];

    if (originalJournalId) {
      const journalElement = document.querySelector(
        `.journalEntry[data-journal-id="${originalJournalId}"]`
      );


      if (journalElement) {
        journalElement.scrollIntoView({ behavior: "smooth", block: "center" });

        const index = userJournals.findIndex(
          (journal) => journal.journal_id === originalJournalId
        );

        if (index !== -1) {
          setExpandedJournalIndex(index);
        }
      }
    } else {
      console.log(`No mapping found for sequential ID: ${sequentialJournalId}`);
    }
  };
  
  const parseSummaryText = (summaryText) => {
    const regex = /(\[\d+(?:, \d+)*\])/g;
    let parts = [];
    let lastIndex = 0;

    summaryText.replace(regex, (match, p1, offset) => {
      if (lastIndex !== offset) {
        parts.push({
          type: "text",
          value: summaryText.slice(lastIndex, offset),
        });
      }
      parts.push({ type: "ref", value: p1 });
      lastIndex = offset + p1.length;
    });

    if (lastIndex < summaryText.length) {
      parts.push({ type: "text", value: summaryText.slice(lastIndex) });
    }

    return parts;
  };

  // New function to parse the summary into sections
  const parseSummaryIntoSections = (summaryText) => {
    const sections = summaryText.split(/(?=###)/);
    return sections.map(section => {
      const [title, ...content] = section.split('\n');
      return {
        title: title.replace('###', '').trim(),
        content: content.join('\n').trim()
      };
    });
  };

  const sections = parseSummaryIntoSections(summaryText);

  return (
    <div className="summaryText">
      {sections.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          <h3 className="summaryTitle">{section.title}</h3>
          {sectionIndex === 2 && !section.content.match(/^[-•]/m) ? (
            <p>
              {parseSummaryText(section.content).map((part, partIndex) => {
                if (part.type === "ref") {
                  const ids = part.value.replace(/[\[\]]/g, "").split(", ");
                  return (
                    <span key={partIndex}>
                      {ids.map((id, idIndex) => (
                        <JournalReference
                          key={idIndex}
                          journalId={id.trim()}
                          scrollToJournal={scrollToJournal}
                        />
                      ))}
                    </span>
                  );
                } else {
                  return <span key={partIndex} dangerouslySetInnerHTML={{__html: part.value.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')}} />;
                }
              })}
            </p>
          ) : (
            <ul>
              {section.content.split('\n').map((point, pointIndex) => {
                if (point.startsWith('-') || point.startsWith('•')) {
                  const parts = parseSummaryText(point.slice(1).trim());
                  return (
                    <li key={pointIndex}>
                      {parts.map((part, partIndex) => {
                        if (part.type === "ref") {
                          const ids = part.value.replace(/[\[\]]/g, "").split(", ");

                          return (
                            <span key={partIndex}>
                              {ids.map((id, idIndex) => (
                                <JournalReference
                                  key={idIndex}
                                  journalId={id.trim()}
                                  scrollToJournal={scrollToJournal}
                                />
                              ))}
                            </span>
                          );
                        } else {
                          return <span key={partIndex} dangerouslySetInnerHTML={{__html: part.value.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')}} />;
                        }
                      })}
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

const JournalReference = ({ journalId, scrollToJournal }) => {
  return (
    <span
      className="journalReference"
      onClick={() => scrollToJournal(journalId)}
      style={{
        textDecoration: "underline",
        cursor: "pointer",
        marginRight: "5px",
      }}
    >
      [{journalId}]
    </span>
  );
};
