import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { FaUpload, FaTimes, FaTrash } from "react-icons/fa";
import "../Dashboard.css";
import PDFDocument from "../../PDFViewer/PDFViewer";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

function ClientDocumentsSection({ selectedUser, isDocumentsOverlayOpen, setIsDocumentsOverlayOpen }) {
  const [documents, setDocuments] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [pdfError, setPdfError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const fetchDocuments = useCallback(async () => {
    // TODO: 
    // if therapist, require that selectedUser is actually their client
    // if client, require that selectedUser is actually their therapist

    if (selectedUser && selectedUser.id) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_END_URL}/api/documents/fetch/${selectedUser.id}`, {
          withCredentials: true,
        });
        if (response.data.length > 0) {
          setDocuments(response.data.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)));
        }
        else {
          setDocuments([]);
        }
      } catch (error) {
        console.error("Failed to fetch documents:", error);
      }
    }
  }, [selectedUser]);

  useEffect(() => {
    if (isDocumentsOverlayOpen) {
      fetchDocuments();
    }
  }, [isDocumentsOverlayOpen, fetchDocuments]);

  const handleCloseOverlay = () => {
    setIsDocumentsOverlayOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf' && file.size <= MAX_FILE_SIZE) {
      setSelectedFile(file);
    } else {
      alert('Please select a PDF file under 5MB.');
      event.target.value = null;
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file to upload.');
      return;
    }

    setIsUploading(true);  // Start loading

    try {
      // check for existing file with same name
      const existingFileResponse = await axios.get(`${process.env.REACT_APP_BACK_END_URL}/api/documents/fetch/${selectedUser.id}?filename=${selectedFile.name}`);
      console.log("existingFileResponse: ", existingFileResponse.data);
      const existingDocument = existingFileResponse.data && existingFileResponse.data.length > 0;
      if (existingDocument) {
        const confirmOverwrite = window.confirm('A file with this name already exists. Do you want to overwrite it?');
        if (!confirmOverwrite) {
          setIsUploading(false);  // Stop loading if user cancels
          return;
        }
      }

      // get signed url from backend, also adds entry to database (for atomicity)
      const signedUrlResponse = await axios.post(`${process.env.REACT_APP_BACK_END_URL}/api/documents/getDocumentSignedUrl`,
        { filename: selectedFile.name, fileType: selectedFile.type, user: selectedUser, existingDocument: existingDocument });
      const signedUrl = signedUrlResponse.data.signedUrl;

      // upload file to s3
      await axios.put(signedUrl, selectedFile, {
        headers: { 'Content-Type': selectedFile.type },
      });
      const documentUrl = signedUrl.split("?")[0];
      console.log("Document uploaded successfully! documentUrl: ", documentUrl);
      setShowUploadModal(false);
      setSelectedFile(null);
      fetchDocuments();
    } catch (error) {
      console.error("Failed to upload document:", error);
      alert('Failed to upload document. Please try again.');
    } finally {
      setIsUploading(false);  // Stop loading regardless of outcome
    }
  };

  const handleDeleteConfirmation = (document) => {
    setDocumentToDelete(document);
    setShowDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    if (!documentToDelete) return;

    try {
      await axios.get(`${process.env.REACT_APP_BACK_END_URL}/api/documents/delete/${documentToDelete.id}?filename=${documentToDelete.title}&userId=${selectedUser.id}`, {
        withCredentials: true,
      });

    } catch (error) {
      console.error("Failed to delete document:", error);
      alert('Failed to delete document. Please try again.');
    } finally {
      setShowDeleteConfirmation(false);
      setDocumentToDelete(null);
    }

    fetchDocuments();
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleDocumentClick = useCallback(async (document) => {
    try {
      if (document.id === selectedDocument?.id) return;

      const response = await axios.get(`${process.env.REACT_APP_BACK_END_URL}/api/documents/download/${document.id}`, {
        withCredentials: true,
      });

      const { url, filename, uploadDate } = response.data;

      setSelectedDocument(prevDoc => {
        if (prevDoc?.id === document.id) return prevDoc;
        return {
          ...document,
          url: url,
          title: filename,
          uploadDate: uploadDate,
        };
      });
    } catch (error) {
      console.error("Failed to fetch document:", error);
      alert('Failed to fetch document. Please try again.');
    }
  }, [selectedDocument]);

  return (
    <div className={`client-documents-overlay ${isDocumentsOverlayOpen ? 'open' : ''}`}>
      <div className="client-documents-wrapper">
        <div className="client-documents-content">
          <div className="client-documents-header">
            <button onClick={() => setShowUploadModal(true)} className="documents-upload-btn">
              <FaUpload /> Upload Document
            </button>
            <button onClick={handleCloseOverlay} className="documents-close-btn">
              <FaTimes /> Close
            </button>
          </div>
          <div className="client-documents-list">
            {documents.length > 0 ? (
              documents.map((doc) => (
                <div key={doc.id} className="client-document" onClick={() => handleDocumentClick(doc)}>
                  <div className="document-info">
                    <p className="document-title">{doc.title}</p>
                    <p className="document-date">{formatDate(doc.uploadDate)}</p>
                  </div>
                  <div className="document-actions">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteConfirmation(doc);
                      }}
                      className="document-delete-btn"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-documents-message">
                <p>No documents uploaded for this client.</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Upload Modal */}
      <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            className="form-control"
            accept=".pdf"
            onChange={handleFileChange}
            disabled={isUploading}
          />
          {isUploading && (
            <div className="mt-3 text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <p className="mt-2">Uploading document...</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUploadModal(false)} disabled={isUploading}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpload} disabled={isUploading || !selectedFile}>
            {isUploading ? 'Uploading...' : 'Upload'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the document "{documentToDelete?.title}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* PDF Preview Modal */}
      <Modal show={selectedDocument !== null} onHide={() => {
        setSelectedDocument(null);
        setPdfError(null);
      }} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedDocument?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDocument?.url ? (
            pdfError ? (
              <div>Error loading PDF: {pdfError}</div>
            ) : (
              <>
                <PDFDocument file={selectedDocument?.url} />
              </>
            )
          ) : (
            <div className="pdf-placeholder">
              <p>No PDF file available for preview.</p>
              <p>This is a placeholder for demonstration purposes.</p>
              <p>Document Title: {selectedDocument?.title}</p>
              <p>Upload Date: {formatDate(selectedDocument?.uploadDate)}</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ClientDocumentsSection;