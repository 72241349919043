import React from 'react';
import { Button, Container, Row, Col, Card, Nav, Navbar, Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaRocket, FaChartLine, FaUserMd, FaClipboardCheck, FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LandingPage.css';
import logo from './empathLogo.png'
import MobileAppSection from './MobileAppSection';
const EmpathAnalytics = require("../../EmpathAnalytics");

const LandingPage = () => {
  const navigate = useNavigate();

  const onGetStarted = () => {
    EmpathAnalytics.logEventFirebase('button_click', 'get_started_login');
    navigate('/login');
  };

  return (
    <div className="landing-page">
      <Navbar expand="lg" fixed="top" className="custom-navbar">
        <Container>
        <Navbar.Brand href="#home">
            <img
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="Empath logo"
            />{' '}
            Empath
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#features">Features</Nav.Link>
              <Nav.Link href="#how-it-works">How It Works</Nav.Link>
              <Nav.Link href="#testimonials">Testimonials</Nav.Link>
              <Button onClick={onGetStarted} variant="outline-primary" className="ms-3">Log In</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <section className="hero-section">
        <div className="hero-background">
          <div className="hero-shape hero-shape-1"></div>
          <div className="hero-shape hero-shape-2"></div>
          <div className="hero-shape hero-shape-3"></div>
        </div>
        <Container>
          <Row className="align-items-center">
            <Col lg={7}>
            <h1 className="hero-title">
                Make Every Client Feel <br />
                <span className="highlight">Like They are Your <i>Only Client</i></span>
              </h1>
              <p className="hero-subtitle">
                Empath empowers therapists to provide personalized care, save time, and improve client outcomes.
              </p>
              <Button onClick={onGetStarted} variant="primary" size="lg" className="hero-cta">
                Get Started
              </Button>
            </Col>
            <Col lg={5}>
              <div className="hero-graphic">
              <div className="hero-graphic-circle">
                  <img 
                    src={logo} 
                    alt="Empath logo" 
                    className="hero-logo"
                  />
                </div>
                <div className="hero-graphic-line hero-graphic-line-1"></div>
                <div className="hero-graphic-line hero-graphic-line-2"></div>
                <div className="hero-graphic-line hero-graphic-line-3"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="features" className="features-section">
        <Container>
          <h2 className="section-title text-center mb-5">Powerful Features to Transform Your Practice</h2>
          <Row>
            {[
              { title: "Instant Client Insights", description: "Comprehensive client progress overview in minutes", icon: <FaRocket /> },
              { title: "AI-Powered Journal Analysis", description: "Uncover patterns from client journal entries", icon: <FaChartLine /> },
              { title: "Effortless Session Notes", description: "Generate detailed reports and save time", icon: <FaClipboardCheck /> },
              { title: "Visual Progress Tracking", description: "Monitor improvement with intuitive charts", icon: <FaUserMd /> }
            ].map((feature, index) => (
              <Col key={index} md={6} lg={3} className="mb-4">
                <Card className="feature-card h-100 border-0 shadow-sm">
                  <Card.Body className="d-flex flex-column align-items-center text-center">
                    <div className="feature-icon-wrapper mb-3">
                      {feature.icon}
                    </div>
                    <Card.Title>{feature.title}</Card.Title>
                    <Card.Text>{feature.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section id="how-it-works" className="how-it-works-section">
        <Container>
          <h2 className="section-title text-center mb-5">Get Started in 3 Simple Steps</h2>
          <Row className="justify-content-center">
            {[
              { title: "Sign Up", description: "Create your account and set up your profile", icon: <FaUserMd />, color: "#4e54c8" },
              { title: "Invite Clients", description: "Invite clients and securely sync their data", icon: <FaRocket />, color: "#ff6b6b" },
              { title: "Gain Insights", description: "Access powerful analytics to improve care", icon: <FaChartLine />, color: "#1abc9c" }
            ].map((step, index) => (
              <Col key={index} md={4} className="mb-4">
                <Card className="how-it-works-card h-100 border-0 shadow-sm">
                  <Card.Body className="d-flex flex-column align-items-center text-center">
                    <div className="step-number-wrapper" style={{backgroundColor: step.color}}>
                      <span className="step-number">{index + 1}</span>
                    </div>
                    <div className="how-it-works-icon-wrapper mt-3" style={{color: step.color}}>
                      {step.icon}
                    </div>
                    <Card.Title>{step.title}</Card.Title>
                    <Card.Text>{step.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <MobileAppSection />

      <section id="testimonials" className="testimonial-section">
        <Container>
          <h2 className="section-title text-center mb-5">What Therapists Are Saying</h2>
          <Carousel indicators={false} className="testimonial-carousel">
            {[
              {
                quote: "Empath gives back almost 30% of the time I typically spent on catching up with clients to dive deeper into the enduring psychological challenges.",
                author: "Kirat Randhawa",
                title: "Therapist and meditation instructor"
              },
              {
                quote: "This platform has revolutionized how I manage my practice. The insights provided are invaluable.",
                author: "Dr. Sarah Johnson",
                title: "Clinical Psychologist"
              },
              {
                quote: "Empath has helped me provide more personalized care to each of my clients. It's a game-changer.",
                author: "Michael Chen",
                title: "Licensed Marriage and Family Therapist"
              }
            ].map((testimonial, index) => (
              <Carousel.Item key={index}>
                <Card className="testimonial-card border-0 shadow">
                  <Card.Body className="text-center">
                    <FaQuoteLeft className="quote-icon left" />
                    <blockquote className="blockquote mb-0">
                      <p>{testimonial.quote}</p>
                      <footer className="blockquote-footer mt-3">{testimonial.author}, <cite title="Source Title">{testimonial.title}</cite></footer>
                    </blockquote>
                    <FaQuoteRight className="quote-icon right" />
                  </Card.Body>
                </Card>
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
      </section>

      <section className="cta-section">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="text-center">
              <h2 className="cta-title mb-4">Ready to Revolutionize Your Therapy Practice?</h2>
              <p className="cta-subtitle mb-4">Join thousands of therapists enhancing client care and saving time with Empath.</p>
              <Button onClick={onGetStarted} variant="primary" size="lg" className="cta-button">
                Get started today
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <footer className="footer">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <p className="mb-0">&copy; 2023 Reality Articulated Inc. All rights reserved.</p>
            </Col>
            <Col md={6} className="text-md-end">
              <a href="/privacy-policy" className="footer-link">Privacy Policy</a>
              <a href="/terms-of-service" className="footer-link">Terms of Service</a>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default LandingPage;