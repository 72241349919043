import * as React from "react";

function IconCloudDrizzleFill(props) {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M4.158 12.025a.5.5 0 01.316.633l-.5 1.5a.5.5 0 01-.948-.316l.5-1.5a.5.5 0 01.632-.317zm6 0a.5.5 0 01.316.633l-.5 1.5a.5.5 0 01-.948-.316l.5-1.5a.5.5 0 01.632-.317zm-3.5 1.5a.5.5 0 01.316.633l-.5 1.5a.5.5 0 01-.948-.316l.5-1.5a.5.5 0 01.632-.317zm6 0a.5.5 0 01.316.633l-.5 1.5a.5.5 0 11-.948-.316l.5-1.5a.5.5 0 01.632-.317zm.747-8.498a5.001 5.001 0 00-9.499-1.004A3.5 3.5 0 103.5 11H13a3 3 0 00.405-5.973z" />
    </svg>
  );
}

export default IconCloudDrizzleFill;