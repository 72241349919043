import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button, Alert, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faUser, faLock, faPhone } from "@fortawesome/free-solid-svg-icons";
import PhoneInput from 'react-phone-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-phone-number-input/style.css';
const EmpathAnalytics = require("../../EmpathAnalytics");

const SignUpClient = () => {
  const { signUpCode, token } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: "",
    phoneNumber: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const handleInputChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handlePhoneNumberChange = (value) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      phoneNumber: value,
    }));
  };

  const handleSubmit = async (e) => {
    EmpathAnalytics.logEventFirebase('form_submission', 'client_sign_up_form');
    e.preventDefault();
    try {
      if (
        !userData.name ||
        !userData.password ||
        !userData.phoneNumber||
        !passwordConfirm
      ) {
        setError("Please fill in all fields.");
        return;
      }

      if (userData.password !== passwordConfirm) {
        setError("Passwords do not match");
        return;
      }

      if (!isPossiblePhoneNumber(userData.phoneNumber)) {
        setError("Enter a valid phone number")
        return;
      }

      const res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/clients/registerClientWithToken`,
        { ...userData, signUpCode, token }
      );
      const userId = res.data.userId;
      const jwtToken = res.data.token;

      const therapistRes = await axios.get(
        `${process.env.REACT_APP_BACK_END_URL}/api/therapists/getTherapistIdBySignupCode/${signUpCode}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const therapistId = therapistRes.data.therapist_id;
      await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/clients/associateClientWithTherapist`,
        { userId, therapistId },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      navigate("/success?existing=false");
    } catch (error) {
      EmpathAnalytics.logEvents('error_message', 'sign_up_client_error');
      if (error.response) {
        const status = error.response.status;
        if (status === 401) {
          setError(
            "Unauthorized: Please use the email to which this invite was sent. (401)"
          );
        } else if (status === 404) {
          setError("Not Found: The requested resource was not found. (404)");
        } else if (status === 409) {
          setError(
            "This email is already in use. Please use a different email or log in instead. (409)"
          );
        } else {
          setError(`Error: ${error.message}`);
        }
      } else if (error.request) {
        setError("Network Error: Unable to connect to the server.");
      } else {
        setError(`Error: ${error.message}`);
      }
    }
  };

  return (
    <div className="signup-page" style={{
      fontFamily: "'Inter', sans-serif",
      color: '#333',
      minHeight: '100vh',
      background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden'
    }}>
      <div className="signup-background" style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0
      }}>
        <div className="signup-shape signup-shape-1" style={{
          position: 'absolute',
          width: '300px',
          height: '300px',
          borderRadius: '50%',
          background: 'rgba(100, 200, 255, 0.1)',
          top: '-100px',
          right: '-100px'
        }}></div>
        <div className="signup-shape signup-shape-2" style={{
          position: 'absolute',
          width: '200px',
          height: '200px',
          borderRadius: '50%',
          background: 'rgba(255, 100, 100, 0.1)',
          bottom: '-50px',
          left: '-50px'
        }}></div>
      </div>
      <Container style={{ position: 'relative', zIndex: 1 }}>
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <div className="signup-card" style={{
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
              borderRadius: '10px',
              padding: '40px',
              boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)'
            }}>
              <h2 className="text-center mb-4" style={{
                fontSize: '2.5rem',
                fontWeight: 700,
                color: '#007bff'
              }}>Create Your Account</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label className="text-muted">Full Name</Form.Label>
                  <div className="input-group">
                    <span className="input-group-text" style={{ backgroundColor: '#007bff', color: 'white' }}>
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                    <Form.Control
                      type="text"
                      placeholder="Enter your full name"
                      name="name"
                      onChange={handleInputChange}
                      required
                      style={{ borderColor: '#007bff' }}
                    />
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPhoneNumber">
                  <Form.Label className="text-muted">Phone Number</Form.Label>
                  <div className="input-group">
                    <span className="input-group-text" style={{ backgroundColor: '#007bff', color: 'white' }}>
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <PhoneInput
                      international
                      countryCallingCodeEditable={false}
                      placeholder="Enter your phone number"
                      defaultCountry="US"
                      value={userData.phoneNumber}
                      onChange={handlePhoneNumberChange}
                      className="form-control"
                      style={{ borderColor: '#007bff' }}
                    />
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPassword">
                  <Form.Label className="text-muted">Password</Form.Label>
                  <div className="input-group">
                    <span className="input-group-text" style={{ backgroundColor: '#007bff', color: 'white' }}>
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                    <Form.Control
                      type={isPasswordVisible ? "text" : "password"}
                      placeholder="Create a strong password"
                      name="password"
                      onChange={handleInputChange}
                      required
                      style={{ borderColor: '#007bff' }}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible) & EmpathAnalytics.logEventFirebase('button_click', 'password_visibility')}
                      style={{ borderColor: '#007bff', zIndex: 1 }}
                    >
                      <FontAwesomeIcon icon={isPasswordVisible ? faEyeSlash : faEye} />
                    </Button>
                  </div>
                  <Form.Text className="text-muted">
                    Password must be at least 8 characters long.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-4" controlId="formConfirmPassword">
                  <Form.Label className="text-muted">Confirm Password</Form.Label>
                  <div className="input-group">
                    <span className="input-group-text" style={{ backgroundColor: '#007bff', color: 'white' }}>
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                    <Form.Control
                      type="password"
                      placeholder="Confirm your password"
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                      required
                      style={{ borderColor: '#007bff' }}
                    />
                  </div>
                </Form.Group>

                <Button 
                  variant="primary" 
                  type="submit" 
                  className="w-100 py-2 fw-bold mb-3"
                  style={{
                    backgroundColor: '#007bff',
                    borderColor: '#007bff',
                    transition: 'all 0.3s ease',
                    position: 'relative',
                    zIndex: 2
                  }}
                  onMouseEnter={(e) => e.target.style.transform = 'translateY(-2px)'}
                  onMouseLeave={(e) => e.target.style.transform = 'translateY(0)'}
                >
                  Sign Up
                </Button>
              </Form>
              <p className="text-center mb-0">
                Already have an account?{" "}
                <Button
                  variant="link"
                  onClick={() => navigate(`/login-existing-client/${signUpCode}/${token}`) & EmpathAnalytics.logEventFirebase('button_click', 'client_login_page')}
                  className="p-0"
                  style={{ color: '#007bff', position: 'relative', zIndex: 2 }}
                >
                  Log in here
                </Button>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUpClient;